import React from 'react';
import { Header } from './Header/Header';
import { About } from './About Me/About';
import { Skills } from './Skills/Skills';
import { Education } from './Education/Education';
import { Portfolio } from './Portfolio/Portfolio';
import { Footer } from './Footer/Footer';


const OldSite = () => {
  return <div>
  <Header />
      <About />
      <Skills />
      <Education />
      <Portfolio />
      <Footer />
</div>;
}
export default OldSite;
