import React from 'react';
import { Container, Row } from 'react-bootstrap';
import './Portfolio.css';
import { PortfolioCards } from './PortfolioCards';
export const Portfolio = () => {
	return (
		<div className="isRecentSection">
			<h2 className="text-center">Recently Crafted..</h2>
			<Container>
				<Row className="containsProjects">
					<PortfolioCards />
				</Row>
			</Container>
		</div>
	);
};
