import React from 'react';
import { Link } from 'react-router-dom';
import './Portfolio.css';
export const UnderConstruction = () => {
	return (
		<div>
			<div className="container d-flex flex-column justify-content-center align-items-center mt-5">
				<h1>
					<i className="fa-solid fa-heart-crack isHeartSymbol"></i>
				</h1>
				<p className="lead">Sorry,</p>
				<p className="lead">
					I am still building the application and will update the live link as
					soon as I deploy it.
				</p>
				<p className="text-muted">
					Meanwhile, why don't you browse my other projects?
				</p>
				<Link to="/" className="btn btn-outline-primary">
					Go Back{' '}
				</Link>
			</div>
		</div>
	);
};
