import React from 'react';
import './App.css';
import NewSite from './components/NewSite';

function App() {
  return (
    <>
      <div>
		<NewSite/>
	  </div>
    </>
  );
}

export default App;
