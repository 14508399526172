import React from 'react';
import { Link } from 'react-router-dom';
import './Portfolio.css';
const MobileApp = () => {
    return (
        <div>
            <div className="container d-flex flex-column justify-content-center align-items-center mt-5">
                <h1>
                    <i className="fa-solid fa-heart-crack isHeartSymbol"></i>
                </h1>
                <p className="lead">Sorry,</p>
                <p className="lead">
                    Some React Native projects may or may not work for everyone due to host limitations so I have left the link out.
                </p>
                <p className='lead'>
                    Please shoot me a LinkedIn message and I'll be more than happy to demo it for you
                </p>
                <p className="text-muted">
                    Meanwhile, why don't you browse my other projects?
                </p>
                <Link to="/" className="btn btn-outline-primary">
                    Go Back
                </Link>
            </div>
        </div>
    );
};

export default MobileApp;