import React from 'react'

const NewSite = () => {
  return (
    <div>
			<div className="container d-flex flex-column justify-content-center align-items-center mt-5">
				<p className="lead">
					I'm rebuilding this. Someday.
				</p>
				
			</div>
		</div>
  )
}

export default NewSite