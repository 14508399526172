import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';
export const Footer = () => {
	const socialLinks = [
		{
			name: 'LinkedIn',
			handleName: 'sam1p',
			url: 'https://www.linkedin.com/in/sam1p',
			iconClass: 'fa-brands fa-linkedin hasColor',
		},
		{
			name: 'Github',
			handleName: 'comingback2life',
			url: 'https://www.github.com/comingback2life',
			iconClass: 'fa-brands fa-github',
		},
	];
	return (
		<div className="isFooterSection text-center">

			<h2 className="isFooterTitle mt-5">You've reached the end.</h2>
			<p className="text-muted">
				or perhaps, we can discuss a new beginning...
			</p>
			<Container>
				<Row>
					<Col className="isContainer">
						{socialLinks.map((item) => {
							return (
								<a href={item.url} key={item.handleName + 'isSocial'}>
									<button>
										<div className="svg-wrapper-1">
											<div className="svg-wrapper">
												<i className={item.iconClass}></i>
											</div>
										</div>
										<span>/{item.handleName}</span>
									</button>
								</a>
							);
						})}
					</Col>
				</Row>
				<p className='text-muted'>I also maintain a <a href="http://blog.samippokharel.com.np" sr-only="blog" target='_blank'>blog</a></p>
			</Container>
		</div>
	);
};
