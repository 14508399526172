import React from 'react';
import './Skills.css';
import { SkillsHelper } from './SkillsHelper';

export const Skills = () => {
	return (
		<div className="mt-3">
			<h2 class="isSkillsHead text-center">
				How do I bring my ideas to life ?
			</h2>
			<p class="text-muted text-center">
				Well, let's meet my friends who help ...
			</p>
			<div className="hasMarquee">
				<div className="marquee">
					<div className="isMarquee">
						<SkillsHelper />
					</div>
				</div>
			</div>
		</div>
	);
};
