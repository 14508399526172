import React, { useState } from 'react';
import './Portfolio.css';
import wowImage from '../../assets/images/projects/wowimage.png';
import expensesTracker from '../../assets/images/projects/expensesTracker.png';
import vocabTestGame from '../../assets/images/projects/vocabTestGame.png';
import reactStaticTweet from '../../assets/images/projects/react-static-tweet.png';
import graphQLProjectMgmt from '../../assets/images/projects/projectmgmt-graphql.png';
import adminCMS from '../../assets/images/projects/eCommerceAdmin.png';
import contactList from '../../assets/images/projects/contact-list.png';
import flexxible from '../../assets/images/Flexxible.png';
import mernAUTH from '../../assets/images/MernAUTH.png';
import redisCache from '../../assets/images/RedisCache.png';
import jobSearchApp from '../../assets/images/RNApp.png';
export const PortfolioCards = ({ requiredSource }) => {
	const [filter, setFilter] = useState('projects');

	const projects = [
		{
			name: 'Job Search Mobile App - React Native',
			url: '/mobileApp',
			image: jobSearchApp,
			git: 'https://github.com/comingback2life/Job-Search-Mobile-app',
			appTech: 'React Native',
			description: {
				techUsed: [
					'fa-react',
					'fa-html5',
					'fa-css3-alt',
				],
			},
		},
		{
			name: 'Flexxible - A dev showcase',
			url: 'https://flexxible-gpb7pqnbf-comingback2life.vercel.app/',
			image: flexxible,
			git: 'https://github.com/comingback2life/flexxible',
			appTech: 'Full Stack',
			description: {
				techUsed: [
					'fa-react',
					'fa-html5',
					'fa-css3-alt',
					'fa-bootstrap',
					'fa-node-js',
				],
			},
		},
		{
			name: 'Github Profile - Redis Caching Demo',
			url: 'https://redis-ts-cache.onrender.com/repos/comingback2life',
			image: redisCache,
			git: 'https://github.com/comingback2life/redis_ts_cache',
			appTech: 'Full Stack',
			description: {
				techUsed: [
					'fa-react',
					'fa-html5',
					'fa-css3-alt',
					'fa-bootstrap',
					'fa-node-js',
				],
			},
		},
		{
			name: 'MERN Stack Authentication Demo',
			url: 'https://vite-static.onrender.com/',
			image: mernAUTH,
			git: 'https://github.com/comingback2life/comingback2life.github.io',
			appTech: 'Full Stack',
			description: {
				techUsed: [
					'fa-react',
					'fa-html5',
					'fa-css3-alt',
					'fa-bootstrap',
					'fa-node-js',
				],
			},
		},
		{
			name: 'eCommerce Admin Dashboard',
			url: '/build404',
			image: adminCMS,
			git: 'https://github.com/comingback2life/furniture-store',
			appTech: 'Full Stack',
			description: {
				techUsed: [
					'fa-react',
					'fa-html5',
					'fa-css3-alt',
					'fa-bootstrap',
					'fa-node-js',
				],
			},
		},
		{
			name: 'Contact List',
			url: 'http://contact-list-card-rs.vercel.app/',
			image: contactList,
			git: 'https://github.com/comingback2life/contact-list-card-rs',
			appTech: 'React',
			description: {
				techUsed: ['fa-react', 'fa-html5', 'fa-css3-alt', 'fa-bootstrap'],
			},
		},
		{
			name: 'Project Management GraphQL',
			url: 'https://projemgmtgql-app.herokuapp.com/',
			image: graphQLProjectMgmt,
			git: 'https://github.com/comingback2life/project-mgmt-graphQL',
			appTech: 'Full Stack',
			description: {
				techUsed: [
					'fa-solid fa-chart-line',
					'fa-react',
					'fa-html5',
					'fa-css3-alt',
					'fa-bootstrap',
				],
				learning: 'Full Stack ',
			},
		},
		{
			name: 'Woolworths Product Image Finder',
			url: 'https://wowimage.comingback2.life/',
			image: wowImage,
			git: 'https://github.com/comingback2life/wow-product-image-finder',
			appTech: 'React',
			description: {
				techUsed: ['fa-react', 'fa-html5', 'fa-css3-alt', 'fa-bootstrap'],
			},
		},
		{
			name: 'Life Without Babel',
			url: '  https://react-static-tweet.vercel.app',
			image: reactStaticTweet,
			git: 'https://github.com/comingback2life/react-static-tweet',
			appTech: 'React',
			description: {
				techUsed: ['fa-react', 'fa-html5', 'fa-css3-alt', 'fa-bootstrap'],
			},
		},
		{
			name: 'Expenses Tracker',
			url: '',
			image: expensesTracker,
			git: 'https://github.com/comingback2life/expenses-tracker-fullstack',
			appTech: 'Full Stack',
			description: {
				techUsed: ['fa-html5', 'fa-css3-alt', 'fa-bootstrap', 'fa-node-js'],
			},
		},
		{
			name: 'Vocabulary Test Game',
			url: 'https://vocab-test-game.vercel.app',
			image: vocabTestGame,
			git: 'https://github.com/comingback2life/vocab-test-game',
			appTech: 'React',
			description: {
				techUsed: ['fa-react', 'fa-html5', 'fa-css3-alt', 'fa-bootstrap'],
				learning:
					'This project uses FETCH API and generates a description for random word, a user can guess the word and if it matches the description they get a point. Else, they are marked negatively',
			},
		},
	];

	const fullStack = projects.filter((i) => i.appTech === 'Full Stack');
	const reactProjects = projects.filter((i) => i.appTech === 'React');
	const reactNativeProjects = projects.filter((i) => i.appTech === 'React Native');
	const appTechUsed = {
		projects,
		fullStack,
		reactProjects,
		reactNativeProjects
	};
	return (
		<>
			<p className="text-muted">
				<div className="containsButtons">
					<div className="isBtn" onClick={() => setFilter('projects')}>
						Everything
					</div>
					<div
						className="isBtn"
						onClick={() => setFilter('reactProjects')}
						name="React"
					>
						React
					</div>
					<div
						className="isBtn"
						onClick={() => setFilter('fullStack')}
						name="Full Stack"
					>
						Full Stack
					</div>
					<div
						className="isBtn"
						onClick={() => setFilter('reactNativeProjects')}
						name="React Native"
					>
						React Native
					</div>
				</div>
			</p>
			{appTechUsed[filter].map((i) => {
				return (
					<div class="card col-xs-4 col-sm-12 col-md-12 col-lg-2 mx-2">
						<img src={i.image} class="w-100 h-100" alt={i.name} />
						<div className="card__overlay">
							<div class="overlay__text">
								<p className="text-lead">{i.name}</p>
								{i.description.techUsed.map((i) => {
									return (
										<span className="text-center inline-block p-2">
											<i className={`fa-brands ${i}`}></i>
										</span>
									);
								})}
								<div>
									<span>
										<a
											href={i.url}
											className="btn btn-outline-dark isButtonCraft"
										>
											<i class="fa-solid fa-eye"></i>
										</a>
									</span>
									<span>
										<a
											href={i.git}
											className="btn btn-outline-dark isButtonCraft"
										>
											<i class="fa-brands fa-github"></i>
										</a>
									</span>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};
