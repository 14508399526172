import React from 'react';
import './Skills.css';
import bootstrapLogo from '../../assets/images/bootstrap.svg';
import htmlLogo from '../../assets/images/html5.svg';
import nodeLogo from '../../assets/images/nodejs.svg';
import jsLogo from '../../assets/images/javascript.svg';
import vsCode from '../../assets/images/vsCode.svg';
import reactLogo from '../../assets/images/react.svg';
import mongoLogo from '../../assets/images/mongo.png';
import gitLogo from '../../assets/images/gitLogo.svg';
import mySqlLogo from '../../assets/images/mySql.svg';
import expressLogo from '../../assets/images/expressLogo.svg';
import cssLogo from '../../assets/images/css3Logo.svg';

export const SkillsHelper = () => {
	const techstacks = [
		{
			logo: jsLogo,
			text: ' JavaScript Logo',
		},
		{
			logo: nodeLogo,
			text: ' Node Logo',
		},
		{
			logo: reactLogo,
			text: ' React Logo',
		},
		{
			logo: mongoLogo,
			text: ' Mongo Logo',
		},
		{
			logo: expressLogo,
			text: 'Express Logo',
		},
		{
			logo: gitLogo,
			text: 'Git Logo',
		},
		{
			logo: mySqlLogo,
			text: 'SQL Logo',
		},
		{
			logo: htmlLogo,
			text: 'HTML5 Logo',
		},
		{
			logo: cssLogo,
			text: 'CSS3 Logo',
		},
		{
			logo: bootstrapLogo,
			text: 'Bootstrap Logo',
		},
		{
			logo: vsCode,
			text: 'VS Code Logo',
		},
	];
	return (
		<ul className="marquee-content">
			{techstacks.map((i) => {
				return (
					<li className="marquee-item" key={i.logo}>
						<img src={i.logo} alt={i.text} className="marquee-image"></img>
					</li>
				);
			})}
		</ul>
	);
};
