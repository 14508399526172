import React from 'react';
import { Col } from 'react-bootstrap';
import './Cards.css';
export const Cards = ({ iconName, children }) => {
	return (
		<Col className="isCard mx-2 mb-4" sm="12" md="3">
			<span className="material-icons">{iconName}</span>
			<p className="mt-5">{children}</p>
		</Col>
	);
};
