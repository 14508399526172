import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UnderConstruction } from './components/Portfolio/UnderConstruction';
import MobileApp from './components/Portfolio/MobileApp';
import OldSite from './components/OldSite';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/build404" element={<UnderConstruction />}></Route>
				<Route path="/mobileApp" element={<MobileApp />}></Route>
				<Route path ='/oldSite' element={<OldSite/>}></Route>
				<Route path="*" element={<App />}></Route>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
