import React from 'react';
import './header.style.css';
import { Row, Col, Container } from 'react-bootstrap';
import samipAvatar from '../../assets/images/SlackAv.png';
export const Header = () => {
	return (
		<div className="head">
			<Container>
				<Row className="isRow">
					<Col>
						<div>
							<img src={samipAvatar} className="img-fluid isAvatar" />
						</div>
					</Col>

					<Col className="intro">
						<strong>
							<h1 className="isHeader">SAMIP POKHAREL</h1>
						</strong>
					</Col>
					<Col sm="12" className="intro">
						<p className="isSoft">I love writing code and stories.</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
