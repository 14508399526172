import React from 'react';
import { Container } from 'react-bootstrap';
import './Education.css';
export const Education = () => {
	return (
		<div className="educationSection p-4">
			<strong>
				<h2 className="isEducationHead mt-4 text-center"> Education </h2>
			</strong>
			<p className="text-muted text-center">Was I a good student ? Maybe.. </p>
			<Container>
				<div class="accordion" id="accordionExample">
					<div class="accordion-item">
						<h2 class="accordion-header" id="headingOne">
							<button
								class="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseOne"
								aria-expanded="true"
								aria-controls="collapseOne"
							>
								Charles Sturt University
							</button>
						</h2>
						<div
							id="collapseOne"
							class="accordion-collapse collapse show"
							aria-labelledby="headingOne"
							data-bs-parent="#accordionExample"
						>
							<div class="accordion-body">
								<p className="text-center">
									<strong>
										Bachelors of Information Technology (Software Design and
										Development).
									</strong>
								</p>
								<p>
									It was truly an honour to attend Charles Sturt University
									during my time in Australia as I really enjoyed my time there.
								</p>
								<strong>Highlights : </strong>
								<ul className="list-unstyled">
									<li className="list-item mb-2 mt-2">
										Peer Discipline Supported Leaders are high-achieving
										students (usually with distinction level Grade Point Average
										scores) who volunteer to make themselves available to
										support their peers. DSL for{' '}
										<strong>
											Programming in Java, Ethics and Professional Practice and
											Database Systems
										</strong>
									</li>
									<li className="mt-2 mb-2">
										<strong>Student Mentor</strong> responsible for organizing
										extra-curricular activities in the university and providing
										student assistance as their first point of contact for
										university related problems.
									</li>
									<li className="mt-2 mb-2">
										Recipient of Executive Dean's Award for academic excellence
										for the year 2020.
									</li>
									<li className="mt-2 mb-2">
										Recipient of Academic Excellence Award awarded by Charles
										Sturt University
									</li>
									<li className="mt-2 mb-2 ">
										Recipient of Student Leadership Award awarded by Charles
										Sturt University
									</li>
									<li className="mt-2 mb-2">
										<strong className="isProject">Xpressly</strong>: Xpressly is
										a mobile wallet application written in JavaScript that aims
										to make money transfer easy globally. During the project, I
										was primarily involved in writing code for the backend and
										creating documentation for both the programmer and the end
										user.
									</li>
									<li className="mt-2 mb-2">
										<strong className="isProject">
											Library Management System
										</strong>
										: Developed a Library management system using Java, JavaFX,
										and SQL as a minor university project. It was an individual
										project and a part of the university requirement.
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 class="accordion-header" id="headingTwo">
							<button
								class="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseTwo"
								aria-expanded="false"
								aria-controls="collapseTwo"
							>
								Australia Institute of Business and Technology
							</button>
						</h2>
						<div
							id="collapseTwo"
							class="accordion-collapse collapse"
							aria-labelledby="headingTwo"
							data-bs-parent="#accordionExample"
						>
							<div class="accordion-body">
								<p className="text-center">
									<strong>
										Diploma and Advance Diploma of Information Technology
									</strong>
								</p>
								<p>
									<strong>Highlights from the course : </strong>
								</p>
								<ul className="list-unstyled">
									<li className="mt-2 mb-2">
										Developed a news portal using PHP and SQL for the database.
									</li>
									<li className="mt-2 mb-2">
										Developed a portfolio as a part of the project using HTML,
										CSS, and PHP
									</li>
									<li className="mt-2 mb-2">
										Worked as a teaching assistant for the lecturer in the
										second year.
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 class="accordion-header" id="headingThree">
							<button
								class="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseThree"
								aria-expanded="false"
								aria-controls="collapseThree"
							>
								Full Stack Web Developer Bootcamp
							</button>
						</h2>
						<div
							id="collapseThree"
							class="accordion-collapse collapse"
							aria-labelledby="headingThree"
							data-bs-parent="#accordionExample"
						>
							<div class="accordion-body">
								<strong>Full Stack Development using MERN Stack.</strong> This
								is probably the place where I learnt more than university.
								<p className="mt-2">
									Students are trained to become Full Stack Web Developers with
									command over MongoDB, Express, Nodejs and React
								</p>
								<strong>Lead Management System: </strong> Developed a full stack
								lead management system as a volunteer work for Dented Code.
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};
