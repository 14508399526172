import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './about.css';
import { Cards } from './Cards/Cards';
export const About = () => {
	return (
		<div>
			<Container>
				<Row className="aboutMe">
					<Col className="aboutCol">
						<h2>About Me</h2>
					</Col>
					<Col className="hiText">
						<p>
							<strong>Hi, I'm Samip Pokharel</strong> I am a Software Developer
							based in Sydney, Australia. It's nice to see you here
						</p>
						<p>
							I spent most of my life in <span className="isNp">Nep</span>,
							where I attended until high school and I travelled to Australia
							for my further studies.
						</p>
					</Col>
				</Row>
				<Container>
					<Row className="lotsOfCards">
						<Cards iconName="code">
							Coding, for me is a way to bring ideas to life and I extensively
							use JavaScript to create beautiful web applications.
						</Cards>
						<Cards iconName="rocket_launch" text="I like this">
							I'm always up for a chat if you have new ideas and are looking for
							someone to collaborate.
						</Cards>
						<Cards iconName="local_cafe">
							If you're looking for a web developer, I'd love to talk to you
							over a cup of coffee. My shout !
						</Cards>
					</Row>
				</Container>
			</Container>
		</div>
	);
};
